import React from "react"
import { Link } from 'gatsby'

import Hero from "../components/hero" 
import MaxWidth from "../components/maxWidth" 
import Layout from "../components/layout"
import SEO from "../components/seo"

const ForIndividualsPage = () => (
	 <>
	 <SEO title="Help &amp; Resources For Employees" keywords={[`Employee Help`, `Employee Referral`, `Employee Assistance`, `Employee Benefits`, `Boston EAP`, 'New England Employee Help']} description="Benefits of an EAP are not limited to issues within the workplace. Corporate EAP Resources provides access to online and offline resources for employees and families"/>

	  <Hero>
	  	<h1>What EAP Means for Employees</h1>
	  	<p>Providing the Help Employees Need</p>
	  </Hero>

	  <Layout>
		<MaxWidth> 
			<p>When complicated issues arise, getting help should be simple. Benefits of an EAP are not limited to issues within the workplace, as problems can occur in ones personal life that may influence workplace performance. Corporate EAP Resources provides access to both online and offline resources for employees and their families.</p>
			
			<p>We provide support for work/life issues including:</p>
			<ul>
				<li>Workplace conflict &amp; difficulties</li>
				<li>Relationship &amp; family resolution</li>
				<li>Depression</li>
				<li>Substance abuse</li>
				<li>Grief/Loss</li>
				<li>Trauma &amp; shock</li>
			</ul>
			
			<h2>How to Get Help</h2>

			<p>Employees and family members can call the employee assistance program directly at any time (24/7), with no referral. Appointments are scheduled at the earliest convenience.</p>

			<Link className='btn primary' to='/contact-us' style={{marginTop: '0px'}}>Contact Us</Link>

			<p>Phone: 1 (866) 635-1712<br/>
			Email: <a href="mailto: info@eap.help">info@eap.help</a></p>
			
			<p><small><span style={{color: '#800000'}}><b>If you need immediate assistance and are unable to wait for a return call, please call 911 or go to your nearest Emergency Room.</b></span></small></p>
			
			<p>Note: If you are an employee planning to call Corporate EAP Resources, please call our office and ask to speak to the EAP Counselor. If, for any reason, a complication arises when you call, ask to speak to the EAP director, and he or she can direct you appropriately. Day and evening appointments are available.</p>
		</MaxWidth>
	  </Layout>
	 </>
)

export default ForIndividualsPage